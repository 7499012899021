//get the brand info
const INIT_STATE = {
    data: null,
    loading: false,
};

const Cobrand = (state = INIT_STATE, action) => {

    switch (action.type) {
        case "@@cobrand/CHANGE_COBRAND" :
            return {data: action.payload};
        default:
            return { ...state };
    }
};

export default Cobrand;