import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        fallbackLng: 'en', // use en if detected lng is not available
        fallbackNS: 'common',
        // keySeparator: false, // we do not use keys in form messages.welcome
        load: "languageOnly",
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        detection: {
            convertDetectedLanguage: (lng) => lng.includes("-") ? lng.substring(0, 2) : lng
        },
        supportedLngs: ['en', 'es'],
    });

export default i18n;
