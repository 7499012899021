const INITIAL_STATE = {
    data: null,
    loading: false,
};

const MessageCenter = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "@@messageCenter/UPDATE_DATA":
            return {...state, data: action.payload};
        case "@@messageCenter/READ_MESSAGE":
            return {
                ...state,
                data: {
                    ...state.data,
                    messages: state.data.messages.map((message) => {
                        return {
                            ...message,
                            read: message.id === action.payload ? true : message.read
                        }
                    })
                }
            };
        case "@@messageCenter/DELETE_MESSAGE":
            return {
                ...state,
                data: {
                    ...state.data,
                    messages: state.data.messages.filter((message) => {
                        return message.id !== action.payload;
                    })
                }
            };
        case "@@messageCenter/DELETE_POLL":
            return {
                ...state,
                data: {
                    ...state.data,
                    poll: action.payload
                }
            }
        case "@@messageCenter/SET_LOADING":
            return {
                ...state,
                loading: true
            }
        case "@@messageCenter/STOP_LOADING":
            return {
                ...state,
                loading: false
            }
        default:
            return {...state};
    }
}

export default MessageCenter;