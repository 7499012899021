import {Modal} from "react-bootstrap";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import {hideGenericModal} from "../redux/layout/actions";
import {useTranslation} from "react-i18next";

const GENERIC_THEME = "light";

export default function GenericModal() {

    const {showGenericModal, genericModalBody, genericModalTheme, genericModalTitle, genericModalHomeButton}= useSelector((state) => state.Layout);
    const {user}= useSelector((state) => state.Auth);
    const dispatch = useDispatch();
    const {t} = useTranslation(["common", 'menu']);

    const GENERIC_ERROR_TITLE = t("global_alert_title_error");
    const GENERIC_ERROR_BODY = t("global_alert_message_generic_error");

    function closeModal() {
        dispatch(hideGenericModal());
    }

    function goHome() {
        if (user) {
            window.AM_gotoHome();
        } else {
            window.AM_gotoRoot();
        }
        closeModal();
    }


    return (
        <>
            <Modal show={showGenericModal} onHide={closeModal} size='sm' dialogClassName="modal-dialog-centered" backdrop={genericModalHomeButton ? "static" : true}>
                <Modal.Header onHide={closeModal} closeButton={!genericModalHomeButton} className={classNames("modal-colored-header", `bg-${genericModalTheme && genericModalTheme.length ? genericModalTheme : GENERIC_THEME}`)}>
                    <h4 className="modal-title">{genericModalTitle && genericModalTitle.length ? genericModalTitle : GENERIC_ERROR_TITLE}</h4>
                </Modal.Header>
                <Modal.Body>
                    <p className="m-0">{genericModalBody && genericModalBody.length ? genericModalBody : GENERIC_ERROR_BODY}</p>
                </Modal.Body>
                <Modal.Footer>
                    {genericModalHomeButton ? <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={goHome}><i className="uil-home-alt"></i> {t("global_back_to_home_label")}</button>
                        : <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={closeModal}>{t("global_button_label_close")}</button>}
                </Modal.Footer>
            </Modal>
        </>
    );
}
