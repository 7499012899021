import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop()  {
    const { pathname } = useLocation();
    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });

    }, [pathname]);

    return null;
}
