// @flow
import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Brand from './Brand/reducers';
import Cobrand from './cobrand/reducers';
import Events from './events/reducers';
import MessageCenter from './messageCenter/reducers';
import Lang from "./langs/reducers";

export default (combineReducers({
    Auth,
    Layout,
    Brand,
    Cobrand,
    Events,
    MessageCenter,
    Lang,
}): any);
