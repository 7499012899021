import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {showGenericModal} from "../layout/actions";
import {AM_webServicePUT} from "../../scripts/api";
import {setUserLang} from "./actions";
import {reloadBrandData} from "../Brand/actions";
import {IMPERSONATE_KEY} from "../../scripts/constants";

// Update member language preferences whenever language is changed
function* callLangsWebService({payload: langData}) {
    const isImpersonated = sessionStorage.getItem(IMPERSONATE_KEY) === "true";
    try {
        if (langData.reloadBrandData) {
            yield put(reloadBrandData());
        }
        if (!isImpersonated) {
            const url = '/member/languagepreference';
            yield call(AM_webServicePUT, url, {language: langData.lang});
        }
        yield put(setUserLang(langData.lang));
    } catch (e) {
        yield put(showGenericModal());
    }
}

function* callLangChangeRequested({payload: langData}) {
    try {
        if (langData.reloadBrandData) {
            yield put(reloadBrandData());
        }
        yield put(setUserLang(langData.lang));

    } catch (e) {
        yield put(showGenericModal());
    }
}

function* watchCallLangsWebService() {
    yield takeEvery("@@langs/MEMBER_LANG_CHANGE_REQUESTED", callLangsWebService);
}

function* watchPublicLangChangeRequesteed() {
    yield takeEvery("@@langs/PUBLIC_LANG_CHANGE_REQUESTED", callLangChangeRequested);
}

export default function* langsSaga() {
    yield all([
        fork(watchCallLangsWebService),
        fork(watchPublicLangChangeRequesteed)
    ]);
}