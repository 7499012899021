export const setUserLang = (lang) => {
    return {
        type: "@@langs/SET_USER_LANG",
        payload: lang,
    }
}

export const publicLangChangeRequested = (lang, reloadBrandData) => {
    return {
        type: "@@langs/PUBLIC_LANG_CHANGE_REQUESTED",
        payload: {lang, reloadBrandData}
    }
}

export const memberLangChangeRequested = (lang, reloadBrandData) => {
    return {
        type: "@@langs/MEMBER_LANG_CHANGE_REQUESTED",
        payload: {lang, reloadBrandData}
    }
}

export const setBrandLangs = (brandLangs) => {
    return {
        type: "@@langs/SET_BRAND_LANGS",
        payload: brandLangs
    }
}