import {all, fork, takeEvery, put, call} from 'redux-saga/effects';
import {handleStartLoading, handleStopLoading, updateMessageCenter} from "./actions";
import * as api from "../../scripts/api";
import {showGenericModal} from "../layout/actions";

function* callMessageCenterWebService() {
    yield put(handleStartLoading())
    try {
        const url = '/member/v2/messagecenter';
        const response = yield call(api.AM_webServiceGet, url);
        if (response.data.poll) {
            response.data.poll.responses.sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                } else if (a.order > b.order) {
                    return 1;
                } else {
                    return 0;
                }
            })
        }

        yield put(updateMessageCenter(response.data));

    } catch(error) {
        yield put(showGenericModal());
    } finally {
        yield put(handleStopLoading())
    }
}

function* watchCallWebService() {
    yield takeEvery('@@messageCenter/CALL_WEB_SERVICE', callMessageCenterWebService)
}

export default function* messageCenterSaga() {
    yield all([
        fork(watchCallWebService)
    ]);
};