const INITIAL_STATE = {
    lang: null,
    brandLangs: []
};

const Lang = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "@@langs/SET_USER_LANG":
            return {...state, lang: action.payload};
        case "@@langs/MEMBER_LANG_CHANGE_REQUESTED":
            return {...state, lang: action.payload};
        case "@@langs/PUBLIC_LANG_CHANGE_REQUESTED":
            return {...state, lang: action.payload};
        case "@@langs/SET_BRAND_LANGS":
            return {...state, brandLangs: action.payload};
        default:
            return {...state};
    }
}

export default Lang;