// @flow

//get the brand info
const INIT_STATE = {
    loading: true,
    needToLoad: true,
};

const Brand = (state = INIT_STATE, action) => {

    switch (action.type) {
        case "@@brand/UPDATE_BRAND" :
            return {...action.payload, needToLoad: false};
        case "@@brand/UPDATE_BRAND_ASYNC" :
            return {...state, loading: true};
        case "@@brand/UPDATE_BRAND_ERROR" :
            return {...state, loading: false};
        case "@@brand/UPDATE_BRAND_SOCIAL" :
            return {...state, loadingSocial: true};
        case "@@brand/SET_BRAND_SOCIAL"  :
            return {...state, socialMedia: action.payload, loadingSocial: false};
        case "@@brand/RELOAD_BRAND_DATA":
            return {...INIT_STATE}
        default:
            return { ...state };
    }
};

export default Brand;