// @flow
import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import brandSaga from './Brand/saga'
import messageCenterSaga from "./messageCenter/saga";
import langsSaga from "./langs/sagas";

export default function* rootSaga(): any {
    yield all([authSaga(), layoutSaga(), brandSaga(), messageCenterSaga(), langsSaga()]);
}
