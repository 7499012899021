import {all, fork, takeEvery, put} from 'redux-saga/effects';
import {setBrandSocialMedia as setBrandSocialMediaAction} from "./actions";

function* updateSocialMenuItem({payload: socialMedia}) {

    try {
        yield put(setBrandSocialMediaAction(socialMedia));
    } catch(error) {
        console.log(error);
    }
}

function* watchModifySocialMedia() {
    yield takeEvery('@@brand/UPDATE_BRAND_SOCIAL', updateSocialMenuItem)
}

export default function* brandSaga() {
    yield all([
        fork(watchModifySocialMedia)
    ]);
};