export const updateBrand = (brand) => ({
    type: "@@brand/UPDATE_BRAND",
    payload: brand,
});

export const updateBrandAsync = (brandName) => ({
    type: "@@brand/UPDATE_BRAND_ASYNC",
    payload: brandName,

});

export const updateBrandError = () => ({
    type: "@@brand/UPDATE_BRAND_ERROR"
});

export const updateBrandSocialMedia = (socialMedia) => ({
    type: "@@brand/UPDATE_BRAND_SOCIAL",
    payload: socialMedia,
});

export const setBrandSocialMedia = (socialMedia) => ({
    type: "@@brand/SET_BRAND_SOCIAL",
    payload: socialMedia,
});

export const reloadBrandData = () => ({
    type: "@@brand/RELOAD_BRAND_DATA",
    payload: true
})