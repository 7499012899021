import React from "react";
import {Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {AM_getURLParameter} from "../scripts/helpers";
import {useTranslation} from "react-i18next";

export default function AccountVerifyModal() {

    const {password_expired, profile_expired, verify_email} = useSelector((state) => (state.Auth.user));
    const { pathname } = useLocation();
    const {t} = useTranslation("common");

    function shouldShowModal() {

        if (pathname === "/account/password") {
            return !password_expired && (profile_expired || verify_email);
        }

        if (pathname === "/account/profile") {
            return !profile_expired && !verify_email && password_expired;
        }

        if (pathname === "/home" && AM_getURLParameter("c", false)) {
            //this means we're current verifying email, so don't show anything
            return false;
        }
        return profile_expired || password_expired || verify_email;
    }

    function getModalTitle() {
        if (password_expired) return t("global_alert_title_password_expired");
        if (profile_expired) return t("global_alert_title_profile_expired");
        if (verify_email) return t("global_alert_title_verify_account");
        return "";
    }

    function getButtonText() {
        if (verify_email) return t("global_button_label_go_to_profile");
        return t("global_button_label_ok");
    }

    function handleButtonSelect() {
        if (password_expired) {
            window.AM_gotoPassword();
        } else if (profile_expired || verify_email) {
            window.AM_gotoProfile()
        }
    }



    return (
        <Modal show={shouldShowModal()} size="sm" dialogClassName="modal-dialog-centered" backdrop="static">
            <Modal.Header className="modal-colored-header bg-light">
                <h4 className="modal-title">{getModalTitle()}</h4>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-0 white-space-preline">{password_expired ? t("global_alert_message_password_expired") :
                    profile_expired ? <>{t("global_alert_message_profile_expired")}</> :
                    verify_email ?  <>{t("global_alert_message_verify_account")}</> : ""}
                </p>
            </Modal.Body>
            <Modal.Footer className="pt-0">
                <Button variant="light" onClick={handleButtonSelect}>{getButtonText()}</Button>
            </Modal.Footer>
        </Modal>
    );
}