// @flow
import * as api from '../../scripts/api';
import {AM_getLoginFailureMessage} from "../../scripts/helpers";

// account
function login(params: any): any {
    const baseUrl = '/member/v5/login';
    return api.AM_webServicePOST(baseUrl, params);
}

function logout(): any {
    const baseUrl = '/public/logout';
    return api.AM_webServicePOST(baseUrl, {});
}

function signup(params: any): any {
    const baseUrl = '/public/v3/signup';
    const finalParams = {...params, type: 1};
    return api.AM_webServicePOST(baseUrl, finalParams);
}

function forgotPassword(params: any): any {
    const baseUrl = '/public/v2/password/reset';
    return api.AM_webServicePOST(baseUrl, params);
}

function forgotPasswordConfirm(params: any): any {
    const baseUrl = '/password/reset/confirm/';
    return api.AM_webServicePOST(baseUrl, params);
    // return api.create(`${baseUrl}`, params);
}

function loginFailureLog(params) {
    const baseUrl = '/member/v2/log';
    const data = {
        message: AM_getLoginFailureMessage(params.error, params.email, params.password, params.token),
        source: `Member login failure for ${params.email ? params.email : "user"}`,
        level: 'error'
    };
    return api.AM_webServicePOST(baseUrl, data);
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm, loginFailureLog };
