export const updateMessageCenter = (messageCenterData) => {
    return {
        type: "@@messageCenter/UPDATE_DATA",
        payload: messageCenterData,
    }
};

export const callMessageCenterWebService = () => {
    return {
        type: "@@messageCenter/CALL_WEB_SERVICE",
        payload: null,
    }
};

export const handleReadMessage = (messageId) => {
    return {
        type: "@@messageCenter/READ_MESSAGE",
        payload: messageId,
    }
};

export const handleDeleteMessage = (messageId) => {
    return {
        type: "@@messageCenter/DELETE_MESSAGE",
        payload: messageId,
    }
}

export const handleDeletePoll = () => {
    return {
        type: "@@messageCenter/DELETE_POLL",
        payload: null,
    }
}

export const handleStartLoading = () => {
    return {
        type: "@@messageCenter/SET_LOADING",
        payload: null
    }
}

export const handleStopLoading = () => {
    return {
        type: "@@messageCenter/STOP_LOADING",
        payload: null
    }
}