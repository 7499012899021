const INITIAL_STATE = {
    events: null,
    loading: false,
};

const Events = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "@@events/UPDATE_EVENTS":
            return {events: [...action.payload], loading: true};
        default:
            return {...state};
    }
}

export default Events;