import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

const TOKEN =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI';
const mock = new MockAdapter(axios, {onNoMatch: "passthrough"});

export function configureFakeBackend() {

    mock.onGet("/public/member/volunteerproviders").reply(200, {
        "status": {
            "code": 200,
            "description": "OK",
            "errors": null,
            "warnings": null,
            "execTime": 34,
            "shutdown": -1
        },
        "data": [
            {
                "id": 1283,
                "name": "American Cancer Society",
                "link": "https://www.cancer.org/support-programs-and-services/patient-transportation.html",
                "description": "Provides transporation services to individuals needing transportation to healthcare or cancer related appointments throughout Virginia",
                "logo": {
                    "light": "/nuride/assets/retailer/257/25768/F2576839.png",
                    "dark": "/nuride/assets/retailer/257/25768/F2576840.png"
                },
                "link_title": "Learn More",
                "region_ids": [
                    1037
                ],
                "enhanced": {
                    "trip_types": {
                        "general": false,
                        "medical": true
                    },
                    "rider_types": {
                        "older_adults": true,
                        "with_disabilities": false,
                        "veterans": true,
                        "youth": true
                    },
                    "has_fees": false,
                    "accessibility_options": {
                        "power_wheelchair": false,
                        "standard_wheelchair": true,
                        "mobility_device": true,
                        "service_animals": true,
                        "travel_aides": true
                    }
                },
                "informational": false
            },
            {
                "id": 1320,
                "name": "LifeCare Medical Transports, Inc.",
                "link": "https://lifecaremedicaltransports.com/",
                "description": "Provides transporation services to individuals with disabilities throughout Virginia",
                "logo": {
                    "light": "/nuride/assets/retailer/257/25772/F2577245.png",
                    "dark": "/nuride/assets/retailer/257/25772/F2577246.png"
                },
                "link_title": "Learn More",
                "region_ids": [
                    1037
                ],
                "enhanced": {
                    "trip_types": {
                        "general": false,
                        "medical": true
                    },
                    "rider_types": {
                        "older_adults": false,
                        "with_disabilities": false,
                        "veterans": false,
                        "youth": false
                    },
                    "has_fees": true,
                    "accessibility_options": {
                        "power_wheelchair": true,
                        "standard_wheelchair": true,
                        "mobility_device": true,
                        "service_animals": true,
                        "travel_aides": false
                    }
                },
                "informational": false
            },
            {
                "id": 1368,
                "name": "DARS BOTH",
                "link": "https://www.vadars.org/",
                "description": "Provides transporation services to individuals with disabilities throughout Middle Peninsula & Northern Neck",
                "logo": {
                    "light": "/nuride/assets/retailer/259/25920/F2592069.png",
                    "dark": "/nuride/assets/retailer/259/25920/F2592070.png"
                },
                "link_title": "Learn More",
                "region_ids": [
                    1037
                ],
                "enhanced": {
                    "trip_types": {
                        "general": true,
                        "medical": true
                    },
                    "rider_types": {
                        "older_adults": true,
                        "with_disabilities": true,
                        "veterans": true,
                        "youth": true
                    },
                    "has_fees": false,
                    "accessibility_options": {
                        "power_wheelchair": false,
                        "standard_wheelchair": false,
                        "mobility_device": false,
                        "service_animals": false,
                        "travel_aides": false
                    }
                },
                "informational": false
            },
            {
                "id": 1369,
                "name": "DARS GENERAL",
                "link": "https://www.vadars.org/",
                "description": "Provides transporation services to individuals with disabilities throughout Middle Peninsula & Northern Neck",
                "logo": {
                    "light": "/nuride/assets/retailer/259/25920/F2592069.png",
                    "dark": "/nuride/assets/retailer/259/25920/F2592070.png"
                },
                "link_title": "Learn More",
                "region_ids": [
                    1037
                ],
                "enhanced": {
                    "trip_types": {
                        "general": true,
                        "medical": false
                    },
                    "rider_types": {
                        "older_adults": true,
                        "with_disabilities": true,
                        "veterans": true,
                        "youth": true
                    },
                    "has_fees": false,
                    "accessibility_options": {
                        "power_wheelchair": false,
                        "standard_wheelchair": false,
                        "mobility_device": false,
                        "service_animals": false,
                        "travel_aides": false
                    }
                },
                "informational": false
            },
            {
                "id": 1370,
                "name": "DARS INFO 1",
                "link": "https://www.vadars.org/",
                "description": "Provides transporation services to individuals with disabilities throughout Middle Peninsula & Northern Neck",
                "logo": {
                    "light": "/nuride/assets/retailer/259/25920/F2592069.png",
                    "dark": "/nuride/assets/retailer/259/25920/F2592070.png"
                },
                "link_title": "Learn More",
                "region_ids": [
                    1037
                ],
                "enhanced": {
                    "trip_types": {
                        "general": false,
                        "medical": true
                    },
                    "rider_types": {
                        "older_adults": true,
                        "with_disabilities": true,
                        "veterans": true,
                        "youth": true
                    },
                    "has_fees": false,
                    "accessibility_options": {
                        "power_wheelchair": false,
                        "standard_wheelchair": false,
                        "mobility_device": false,
                        "service_animals": false,
                        "travel_aides": false
                    }
                },
                "informational": true
            },
            {
                "id": 1371,
                "name": "DARS INFO 2",
                "link": "https://www.vadars.org/",
                "description": "Provides transporation services to individuals with disabilities throughout Middle Peninsula & Northern Neck",
                "logo": {
                    "light": "/nuride/assets/retailer/259/25920/F2592069.png",
                    "dark": "/nuride/assets/retailer/259/25920/F2592070.png"
                },
                "link_title": "Learn More",
                "region_ids": [
                    1037
                ],
                "enhanced": {
                    "trip_types": {
                        "general": false,
                        "medical": true
                    },
                    "rider_types": {
                        "older_adults": true,
                        "with_disabilities": true,
                        "veterans": true,
                        "youth": true
                    },
                    "has_fees": false,
                    "accessibility_options": {
                        "power_wheelchair": false,
                        "standard_wheelchair": false,
                        "mobility_device": false,
                        "service_animals": false,
                        "travel_aides": false
                    }
                },
                "informational": false
            },
        ]
    });

    mock.onGet("/public/brand?domain=qa-connectingva.agilemile.com").reply(200, {
        "status": {
            "code": 200,
            "description": "OK",
            "errors": null,
            "warnings": null,
            "execTime": 3,
            "shutdown": -1
        },
        "data": {
            "id": 1037,
            "languages": [
                "en"
            ],
            "app_name": "ConnectingVA",
            "base_url": "https://qa-connectingva.agilemile.com",
            "website": "https://connectingva.drpt.virginia.gov/",
            "sender_email": "noreply-qa@agilemile.com",
            "contact_website": "",
            "contact_email": "",
            "landing_id": -1,
            "landing2_id": 80,
            "cobrand_1": true,
            "cobrand_2": false,
            "itunes_app_id": "1570022998",
            "itunes_app_link": "https://apps.apple.com/us/app/id1570022998?mt=8",
            "android_app_id": "com.agilemile.commuteva",
            "android_app_link": "https://play.google.com/store/apps/details?id=com.agilemile.commuteva",
            "google_web_key": "AIzaSyClSqz3CHR29e3e-SbEA3c4amcHkzuoBCA",
            "google_ios_sdk_key": "AIzaSyDumG3hSH6qgrLtHFqrVSpOsBQMXh723EM",
            "google_android_sdk_key": "AIzaSyDLOEfcxc7gRwiEcYuuPAO3agxcIlkqgkY",
            "google_ios_android_api_key": "AIzaSyC15wr0b9MBbDa4dBgzDNlN2NXTiOrrqUk",
            "google_tp_iframe_key": "AIzaSyAO86L5QIKMt9aA2EHCrm7nZ_W5eWPg6r8",
            "redirect": true,
            "special_events": false,
            "use_entertainment": true,
            "erhgrh_id": 0,
            "erhgrh_name": null,
            "require_trip_time": false,
            "print_trip_calendar": "",
            "brown_bag": false,
            "drive_alone": true,
            "scooter": true,
            "compressed_work_week": true,
            "on_the_way": true,
            "record_trip_days_back": 30,
            "record_trip_days_forward": 30,
            "trip_planner": true,
            "events": true,
            "one_time_trips": true,
            "school_pool": false,
            "trip_tracking": true,
            "covid_info": false,
            "has_partner_orgs": false,
            "map_lat": 37.5416,
            "map_lng": -78.4667,
            "map_zoom": 8,
            "drive_alone_points": 0,
            "commute_only": false,
            "enhanced_assisted_rides": true
        }
    });

    return true;
}
