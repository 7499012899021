export const AuthActionTypes = {
    API_RESPONSE_SUCCESS: '@@auth/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@auth/API_RESPONSE_ERROR',

    LOGIN_USER: '@@auth/LOGIN_USER',
    LOGOUT_USER: '@@auth/LOGOUT_USER',
    SIGNUP_USER: '@@auth/SIGNUP_USER',
    DELETE_USER: '@@auth/DELETE_USER',
    DELETE_USER_COMPLETE: '@@auth/DELETE_USER_COMPLETE',
    FORGOT_PASSWORD: '@@auth/FORGOT_PASSWORD',
    FORGOT_PASSWORD_CHANGE: '@@auth/FORGOT_PASSWORD_CHANGE',
    UPDATE_PHOTO: '@@auth/UPDATE_PHOTO',
    UPDATE_PASSWORD: '@@auth/UPDATE_PASSWORD',
    UPDATE_HOME: '@@auth/UPDATE_HOME',
    UPDATE_WORK: '@@auth/UPDATE_WORK',
    UPDATE_LOCATIONS: '@@auth/UPDATE_LOCATIONS',
    UPDATE_EMAILS: '@@auth/UPDATE_EMAILS',
    UPDATE_PROFILE: '@@auth/UPDATE_PROFILE',
    UPDATE_EMAIL_VERIFY: '@@auth/UPDATE_EMAIL_VERIFY',
    RESET: '@@auth/RESET',
};
