import React, {useEffect, useState} from 'react';
import Routes from './routes/Routes';
import BrandSelector from "./branding/brandconfig";

// Navigation
import {setGlobalFunctions} from './scripts/navigation';

// CSS
import './assets/scss/Creative.scss';

// Generic error modal
import GenericModal from "./components/GenericModal";
import {configureFakeBackend} from "./helpers/fake-backend";

/*
 * Main app component
 */
export default function App() {

    const [ready, setReady] = useState(false);

    useEffect(() => {
        // Set Global Functions
        setGlobalFunctions();

        // Configure Backend
        if (process.env.NODE_ENV !== 'production') {
            configureFakeBackend();
        }

        setReady(true);

    }, [])


    return (
        <>
            {
                ready && (
                    <>
                        <BrandSelector>
                            <Routes/>
                        </BrandSelector>
                        <GenericModal/>
                    </>
                )
            }

        </>
    );
};
