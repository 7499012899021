import React, {Suspense, useEffect} from 'react';
import {Redirect, Route, useLocation} from 'react-router-dom';
import * as api from "../scripts/api";
import {useSelector} from "react-redux";
import {Container} from 'react-bootstrap';
import * as layoutConstants from "../constants/layout";
import {publicPrivateRoutes} from "../constants";
import classnames from "classnames";

const Topbar = React.lazy(() => import('../layouts/Topbar'));
const Footer = React.lazy(() => import('../layouts/Footer'));

const loading = () => <div className=""><p></p></div>;

/**
 * Public Route checks if the route can be accessed publicly or privately (ie. FAQs, help, etc.).
 * @param {*} param0
 * @returns
 */
const PublicRoute = ({component: Component, name, includeLayout, ...rest}) => {
    //get light/dark theme
    const {theme} = useSelector((state) => (state.Layout));
    const isLight = theme === layoutConstants.THEME_LIGHT;
    //get the path and check if there is a private version of it, if there is and the user can be logged in, redirect to that
    const location = useLocation();
    const queryParams = location.search
    const path = location.pathname;
    const publicPrivatePath = publicPrivateRoutes.find(route => route.publicPath == path);
    const {app_name} = useSelector((state) => state.Brand);

    let logUserIn = api.AM_isUserAuthenticated() || api.AM_getTokenIfNoAuthRequired();

    useEffect(() => {
        document.title = `${app_name} - ${name}`;
    }, []);

    return (
        <Route
            {...rest}
            render={(props) => {

                if (publicPrivatePath || includeLayout) {
                    if (logUserIn && publicPrivatePath) {
                        return <Redirect to={`${publicPrivatePath.privatePath}${queryParams}`} />

                    } else {
                        return (
                            <>
                                <Suspense fallback={loading()}>
                                    <Topbar navCssClasses={classnames(!includeLayout ? "mb-4" : "", "topnav-navbar")}
                                            hideLogo={false} isLight={isLight}/>
                                </Suspense>
                                <Container fluid className="px-3 px-lg-2 public-content">
                                    <Component {...props} />
                                </Container>
                                <Suspense fallback={loading()}>
                                    <Footer footerCssClasses="footer footer-public" user={false}/>
                                </Suspense>
                            </>
                        )
                    }
                } else {
                    return <Component {...props} />;
                }
            }}
        />
    );
};

export default PublicRoute;