import React from 'react';

import PrivateRoute from './PrivateRoute';
import PublicRoute from "./PublicRoute";

/*
* Import statements for all pages
*/

// Landing Page
const LandingPage = React.lazy(() => import('../am_pages/landing'));

// Trip Planner
const TripPlanner = React.lazy(() => import('../am_pages/find'));

// Home page
const Home = React.lazy(() => import('../am_pages/home'));

// Rewards
const Rewards = React.lazy(() => import('../am_pages/rewards'));

// More
const FAQSNEW = React.lazy(() => import('../am_pages/faq'));
const CommuteCalculator = React.lazy(() => import('../am_pages/commuteCalculator'));
const Legal = React.lazy(() => import('../am_pages/legal'));
const PrivacyPolicy = React.lazy(() => import('../am_pages/privacyPolicy'));
const ContactUs = React.lazy(() => import('../am_pages/contactUs'));
const Help = React.lazy(() => import('../am_pages/help'));
const Partner = React.lazy(() => import('../am_pages/partner'));

// Dashboard
const Organizations = React.lazy(() => import('../am_pages/organizations'));
const Org = React.lazy(() => import('../am_pages/org'));

// Misc
const Wallet = React.lazy(() => import('../am_pages/wallet'));
const Event = React.lazy(() => import('../am_pages/event'));
const Team = React.lazy(() => import('../am_pages/team'));
const Individual = React.lazy(() => import('../am_pages/individual'));

// Account
const ContactInfo = React.lazy(() => import('../am_pages/contactInfo'));
const DoNotContact = React.lazy(() => import('../am_pages/doNotContact'));
const Emails = React.lazy(() => import('../am_pages/emails'));
const Locations = React.lazy(() => import('../am_pages/locations'));
const Password = React.lazy(() => import('../am_pages/password'));
const Photo = React.lazy(() => import('../am_pages/photo'));
const AM_Profile = React.lazy(() => import('../am_pages/profile'));
const Racing = React.lazy(() => import('../am_pages/racing'));
const Supervisor = React.lazy(() => import('../am_pages/supervisor'));
const Vehicles = React.lazy(() => import('../am_pages/vehicles'));
const Volunteer = React.lazy(() => import('../am_pages/volunteer'));
const DeleteAccount = React.lazy(() => import('../am_pages/deleteAccount'));

// Trips
const Trip = React.lazy(() => import('../am_pages/trip'));
const TripProfiles = React.lazy(() => import('../am_pages/tripProfiles'));
const Calendar = React.lazy(() => import('../am_pages/calendar'));
const Syncing = React.lazy(() => import('../am_pages/syncing'));
const AutoRecord = React.lazy(() => import('../am_pages/autoRecord'));
const Points = React.lazy(() => import('../am_pages/points'));
const CompletedTrips = React.lazy(() => import('../am_pages/completedTrips'));

// Other public routes
const Oauth = React.lazy(() => import('../am_pages/oauth'));
const ErrorPage = React.lazy(() => import('../am_pages/pageNotFound'));
const TripPlannerIFrame = React.lazy(() => import('../am_pages/tripPlannerIFrame'));
const EmailVerify = React.lazy(() => import('../am_pages/emailVerify'));
const Unsubscribe = React.lazy(() => import('../am_pages/unsubscribe'));
const MyStats = React.lazy(() => import('../am_pages/myStats'));

// Auth
const Login = React.lazy(() => import('../am_pages/login'));
const Logout = React.lazy(() => import('../am_pages/logout'));
const Register = React.lazy(() => import('../am_pages/join'));
const ForgetPassword = React.lazy(() => import('../am_pages/forgotPassword'));
const Deleted = React.lazy(() => import('../am_pages/deleted'));
const Verify = React.lazy(() => import('../am_pages/verify'));

/*
* Routes
*/

// Root
const rootRoute = {
    path: '/',
    name: "landing_page_title",
    component: LandingPage,
    route: PublicRoute,
};

// Trip Planner
const tripPlannerRoutes = {
    path: '/find',
    name: "mi-trip-planner",
    route: PrivateRoute,
    icon: 'uil-map',
    component: TripPlanner,
};

// Home
const homeRoutes = {
    path: '/home',
    name: "mi-home",
    route: PrivateRoute,
    component: Home,
};

// Rewards
const rewardsRoutes = {
    name: "bottom_nav_rewards",
    route: PrivateRoute,
    children: [
        {
            path: '/rewards',
            name: "bottom_nav_rewards",
            component: Rewards,
            route: PrivateRoute,
        }
    ]
};

// More
const moreRoutes = {
    // path: '/more',
    name: 'More',
    route: PrivateRoute,
    icon: 'uil-plus',
    children: [
        {
            path: '/more/faqs',
            name: "more_textview_label_faqs",
            component: FAQSNEW,
            route: PrivateRoute,
            icon: 'uil-question-circle'
        },
        {
            path: '/more/commute_calculator',
            name: "more_textview_label_commute_calculator",
            component: CommuteCalculator,
            route: PrivateRoute,
        },
        {
            path: '/more/legal',
            name: "more_textview_label_legal",
            component: Legal,
            route: PrivateRoute,
        },
        {
            path: '/more/privacy_policy',
            name: "privacy_policy_title",
            component: PrivacyPolicy,
            route: PrivateRoute,
        },
        {
            path: '/more/contact_us',
            name: "more_textview_label_contact_us",
            component: ContactUs,
            route: PrivateRoute,
        },
        {
            path: '/more/help',
            name: "more_textview_label_help",
            component: Help,
            route: PrivateRoute,
        },
        {
            path: '/more/partner',
            name: "partner_page_title",
            component: Partner,
            route: PrivateRoute,
        },
    ],
};

// Dashboard
const adminRoutes = {
    name: 'Admin',
    route: PrivateRoute,
    icon: 'uil-dashboard',
    children: [
        {
            path: '/admin/orgs',
            name: 'Organizations',
            component: Organizations,
            route: PrivateRoute,
        },
        {
            path: '/admin/org/:id',
            name: 'Organization',
            component: Org,
            route: PrivateRoute,
        },
    ]
};

// Miscellaneous
const miscRoutes = {
    name: 'Misc',
    route: PrivateRoute,
    children: [
        {
            path: '/wallet',
            name: "wallet_title",
            component: Wallet,
            route: PrivateRoute,
        },
        {
            path: '/transit_passes',
            name: "wallet_title",
            component: Wallet,
            route: PrivateRoute,
        },
        {
            path: '/event/:id',
            name: "special_event_page_title",
            component: Event,
            route: PrivateRoute,
        },
        {
            path: '/race_team/:id',
            name: "team_title",
            component: Team,
            route: PrivateRoute,
        },
        {
            path: '/race_individual/:id',
            name: "individual_title",
            component: Individual,
            route: PrivateRoute,
        },
        {
            path: '/more/my_stats',
            component: MyStats,
            name: "my_stats_title",
            route: PrivateRoute,
        }
    ]
};

// Account
const accountRoutes = {
    name: 'Account',
    route: PrivateRoute,
    icon: 'uil-user',
    children: [
        {
            path: '/account/contact_info',
            name: "me_textview_label_contact_info",
            component: ContactInfo,
            route: PrivateRoute,
        },
        {
            path: '/account/do_not_contact',
            name: "me_textview_label_do_not_contact",
            component: DoNotContact,
            route: PrivateRoute,
        },
        {
            path: '/account/emails',
            name: "me_textview_label_emails",
            component: Emails,
            route: PrivateRoute,
        },
        {
            path: '/account/locations',
            name: "me_textview_label_locations",
            component: Locations,
            route: PrivateRoute,
        },
        {
            path: '/account/password',
            name: "me_textview_label_password",
            component: Password,
            route: PrivateRoute,
        },
        {
            path: '/account/photo',
            name: "me_textview_label_photo",
            component: Photo,
            route: PrivateRoute,
        },
        {
            path: '/account/profile',
            name: "me_textview_label_profile",
            component: AM_Profile,
            route: PrivateRoute,
        },
        {
            path: '/account/racing',
            name: "me_textview_label_racing",
            component: Racing,
            route: PrivateRoute,
        },
        {
            path: '/account/supervisor',
            name: "me_textview_label_supervisor",
            component: Supervisor,
            route: PrivateRoute,
        },
        {
            path: '/account/vehicles',
            name: "me_textview_label_vehicles",
            component: Vehicles,
            route: PrivateRoute,
        },
        {
            path: '/account/volunteer',
            name: "me_textview_label_volunteer",
            component: Volunteer,
            route: PrivateRoute,
        },
        {
            path: '/account/delete_account',
            name: "me_textview_label_delete_account",
            component: DeleteAccount,
            route: PrivateRoute,
        }
    ]
};

// Trips
const tripRoutes = {
    name: 'Trips',
    route: PrivateRoute,
    icon: 'mdi mdi-calendar-check',
    children: [
        {
            path: '/trips/trip',
            name: "trips_textview_label_record_a_trip",
            component: Trip,
            route: PrivateRoute,
        },
        {
            path: '/trips/trip_profiles',
            name: "trips_textview_label_trip_profiles",
            component: TripProfiles,
            route: PrivateRoute,
        },
        {
            path: '/trips/calendar',
            name: "trips_textview_label_trip_calendar",
            component: Calendar,
            route: PrivateRoute
        },
        {
            path: '/trips/syncing',
            name: "trips-syncing",
            component: Syncing,
            route: PrivateRoute
        },
        {
            path: '/trips/auto_record_trips',
            name: "trips_textview_label_auto_record_trips",
            component: AutoRecord,
            route: PrivateRoute
        },
        {
            path: '/trips/points',
            name: "trips_textview_label_points_and_redemptions",
            component: Points,
            route: PrivateRoute
        },
        {
            path: '/trips/trips',
            name: "trips_textview_label_completed_trips",
            component: CompletedTrips,
            route: PrivateRoute
        }
    ]
};

// All private routes
const appRoutes = [
    tripPlannerRoutes,
    homeRoutes,
    moreRoutes,
    accountRoutes,
    tripRoutes,
    rewardsRoutes,
    miscRoutes,
    adminRoutes
];

// Misc public routes
const otherPublicRoutes = [
    {
        path: '/oauth',
        name: '',
        component: Oauth,
        route: PublicRoute,
        exact: true
    },
    {
        path: '/404',
        name: "error_title",
        component: ErrorPage,
        route: PublicRoute,
        exact: true
    },
    {
        path: '/faqs',
        name: "more_textview_label_faqs",
        component: FAQSNEW,
        route: PublicRoute,
        exact: true,
        includeLayout: true
    },
    {
        path: '/commute_calculator',
        name: "more_textview_label_commute_calculator",
        component: CommuteCalculator,
        route: PublicRoute,
        exact: true,
        includeLayout: true
    },
    {
        path: '/legal',
        name: "more_textview_label_legal",
        component: Legal,
        route: PublicRoute,
        exact: true,
        includeLayout: true
    },
    {
        path: '/privacy_policy',
        name: "privacy_policy_title",
        component: PrivacyPolicy,
        route: PublicRoute,
        exact: true,
        includeLayout: true
    },
    {
        path: '/contact_us',
        name: "more_textview_label_contact_us",
        component: ContactUs,
        route: PublicRoute,
        exact: true,
        includeLayout: true
    },
    {
        path: '/trip_planner_iframe',
        component: TripPlannerIFrame,
        name: "mi-trip-planner",
        route: PublicRoute,
        exact: true
    },
    {
        path: '/email_verify',
        component: EmailVerify,
        name: "email_verify_title",
        route: PublicRoute,
        exact: true,
        includeLayout: true
    },
    {
        path: '/unsubscribe',
        component: Unsubscribe,
        name: "unsubscribe_title",
        route: PublicRoute,
        exact: true,
        includeLayout: true
    },
    {
        path: '/my_stats',
        component: MyStats,
        name: "my_stats_title",
        route: PublicRoute,
        exact: true,
        includeLayout: true,
    }
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// Auth
const authRoutes = [
    {
        path: '/account/login',
        name: "global_button_label_sign_in",
        component: Login,
        route: PublicRoute,
        exact: true
    },
    {
        path: '/account/logout',
        name: "me_textview_label_sign_out",
        component: Logout,
        route: PublicRoute,
        exact: true
    },
    {
        path: '/account/register',
        name: "join_email_title",
        component: Register,
        route: PublicRoute,
        exact: true
    },
    {
        path: '/account/forget-password',
        name: "forgot_title",
        component: ForgetPassword,
        route: PublicRoute,
        exact: true
    },
    {
        path: '/account/deleted',
        name: "account_deleted_page_title",
        component: Deleted,
        route: PublicRoute,
        exact: true
    },
    {
        path: '/account/verify',
        name: "verify_title",
        component: Verify,
        route: PublicRoute,
        exact: true
    }
];

// All routes
const authProtectedRoutes = [...appRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes, rootRoute];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export {publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes};
