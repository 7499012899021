/***********************************************
 *
 *  API functions to be used for calling web services, authorizing the user, setting/updating user in storage, etc.
 *
 ***********************************************/
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import qs from 'qs';
import {
    AGILE_MILE_API_KEY,
    AGILE_MILE_API_VERSION,
    AUTH_SESSION_KEY,
    AUTH_TOKEN_KEY, IMPERSONATE_KEY,
    USPS_SERVER,
    USPS_USERID
} from "./constants";
import {AM_encodeXmlSpecialChars} from "./helpers";
import {login} from "../helpers";
import {authApiResponseSuccess} from "../redux/auth/actions";
import {AuthActionTypes} from "../redux/auth/constants";
import {getStore} from "../redux/store";

const WEB_SERVICE_STATUS_ERROR = 500;
const WEB_SERVICE_STATUS_SUCCESS = 200;

const host = (window.location.origin || `${window.location.protocol}//${window.location.host}`)
axios.defaults.baseURL = host + "/nuride-api/api";
axios.defaults.headers.post['Content-Type'] = 'application/json';

const exemptMemberCalls = ['/member/v5/login', "/member/v2/log", "/member/verify", "/public/member/unsubscribe"];


/***********************************************
 *
 *  Create interceptors to handle errors
 *
 ***********************************************/

axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (response.data.status) {
            //calling our own api, find a better way to identify this
            if (response.data.status.code !== WEB_SERVICE_STATUS_SUCCESS) return Promise.reject(response.data);
            return response.data;
        }
        // calling fake api (for now)
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let codeToReturn = error.response.status;
        if (error?.response?.data?.message === "601: Account locked") codeToReturn = 601;
        return Promise.reject(codeToReturn);
    }
);

/***********************************************
 *
 *  Set HTTP Header for a request
 *
 ***********************************************/
const AM_setHTTPHeaders = (contentType) => {
    return {
        'X-CSRF-TOKEN': '',
        'X-API-VERSION': AGILE_MILE_API_VERSION,
        'X-API-KEY': AGILE_MILE_API_KEY,
        'Content-Type': contentType ? contentType : 'application/x-www-form-urlencoded; charset=UTF-8',
        'language': window.localStorage.i18nextLng || 'en'
    }
}

/***********************************************
 *
 *  Makes a GET web service call
 *
 ***********************************************/
const AM_webServiceGet = (url, data) => {
    return AM_webServiceByMethod('get', url, data);
}

/***********************************************
 *
 *  Makes a POST web service call
 *
 ***********************************************/
const AM_webServicePOST = (url, data, allowDots, bracketArrays) => {
    return AM_webServiceByMethod('post', url, data, allowDots, bracketArrays);
}

/***********************************************
 *
 *  Makes a POST web service call with JSON content type
 *
 ***********************************************/
async function AM_webServicePOST_JSON(url, data) {
    let makeCall = true;

    if (AM_shouldLoginUserBeforeCall(url)) {
        makeCall = await loginFirst();
    }

    if (makeCall) {
        const requestInfo = {
            method: "post",
            url: url,
            data: JSON.stringify(data),
            params: null,
            headers: AM_setHTTPHeaders("application/json; charset=utf-8")
        }
        return axios(requestInfo);
    } else {
        return new Promise((resolve, reject) => {
            reject();
        });
    }
}

/***********************************************
 *
 *  Makes a PUT web service call
 *
 ***********************************************/
const AM_webServicePUT = (url, data) => {
    return AM_webServiceByMethod('put', url, data);
}

/***********************************************
 *
 *  Makes a DELETE web service call
 *
 ***********************************************/
const AM_webServiceDELETE = (url, data) => {
    return AM_webServiceByMethod('delete', url, data);
}

/***********************************************
 *
 *  Makes a web service call
 *
 ***********************************************/
async function AM_webServiceByMethod(type, url, data, allowDots, bracketArrays) {
    let makeCall = true;

    if (AM_shouldLoginUserBeforeCall(url)) {
        makeCall = await loginFirst();
    }

    if (makeCall) {
        let options = {};
        if (allowDots) options.allowDots = true;
        if (bracketArrays) options.arrayFormat = 'brackets';
        const requestInfo = {
            method: type,
            url: url,
            data: type == 'post' || type == 'put' || type == 'delete' ? qs.stringify(data, options) : null,
            params: type == 'get' ? data : null,
            headers: AM_setHTTPHeaders()
        }
        return axios(requestInfo);
    } else {
        return new Promise((resolve, reject) => {
            reject();
        });
    }
}

/***********************************************
 *
 *  Makes login call before member web service
 *
 ***********************************************/
function loginFirst() {
    return new Promise((resolve, reject) => {

        login(null).then(response => {
            AM_setLoggedInUser(response.data);
            AM_setAuthorization(response.data.token);
            AM_setTokenToStorage(response.data.token);
            getStore().dispatch(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, response.data));
            resolve(true);
        }).catch(error => {
            reject(false);
        });
    });
}

/***********************************************
 *
 *  Checks if we need to login use before making web service call
 *
 ***********************************************/
function AM_shouldLoginUserBeforeCall(url) {
    return url.includes("/member")
        && !exemptMemberCalls.includes(url)
        && !AM_isUserAuthenticated()
        && window.location.pathname !== "/trip_planner_iframe"
        && AM_getTokenFromStorage()?.length > 0;
}

/***********************************************
 *
 *  Makes a web service call with some data bracketed and other data not bracketed
 *  This is neccesary becuase it seems we need arrays with primitive values bracketed, but arrays with non-primitive values not bracketed
 *
 ***********************************************/
async function AM_webServiceMultiTypePost(url, nonBracketedData, bracketedData) {
    let makeCall = true;
    if (AM_shouldLoginUserBeforeCall(url)) {
        makeCall = await loginFirst();
    }

    if (makeCall) {
        const requestInfo = {
            method: "post",
            url: url,
            data: qs.stringify(nonBracketedData) + "&" + qs.stringify(bracketedData, {arrayFormat: 'brackets'}),
            headers: AM_setHTTPHeaders()
        }
        return axios(requestInfo);
    } else {
        return new Promise((resolve, reject) => {
            reject();
        });
    }
}

/***********************************************
 *
 *  Makes a web service call to a 3rd party source
 *
 ***********************************************/
const AM_webServiceGetExternal = (url, data) => {
    const requestInfo = {
        method: 'get',
        url: url,
        data: null,
        params: data,
        headers: {Authorization: null, Accept: 'application/json'},
        baseURL: ''
    }
    return axios(requestInfo);
}

/***********************************************
 *
 *  Uploads a file via the postForm shortcut
 *
 ***********************************************/
async function AM_webServiceFileUpload(url, data) {
    let makeCall = true;
    if (AM_shouldLoginUserBeforeCall(url)) {
        makeCall = await loginFirst();
    }

    if (makeCall) {
        return axios.postForm(url, data, {headers: AM_setHTTPHeaders()});
    } else {
        return new Promise((resolve, reject) => {
            reject();
        });
    }
}

/***********************************************
 *
 *  Sets JWT user token to local storage
 *
 ***********************************************/
const AM_setTokenToStorage = (token) => {
    if (token) localStorage.setItem(AUTH_TOKEN_KEY, token);
    else {
        localStorage.removeItem(AUTH_TOKEN_KEY);
    }
}
/***********************************************
 *
 *  Gets JWT user token from session or local storage
 *
 ***********************************************/
const AM_getTokenFromStorage = () => {
    let token;
    const session = AM_getUserFromSession();
    if (session) token = session.token;
    else {
        token = localStorage.getItem(AUTH_TOKEN_KEY);
    }
    return token;
}
/***********************************************
 *
 *  Gets JWT user token if 2FA is not required on it
 *
 ***********************************************/
const AM_getTokenIfNoAuthRequired = () => {
    const token = AM_getTokenFromStorage();
    if (!token) return null;

    const decoded = jwtDecode(token);

    if (decoded.auth != 1) {
        return token;
    }

    return null;
}
/***********************************************
 *
 *  Gets the user info from session storage
 *
 ***********************************************/
const AM_getUserFromSession = () => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
    // return null;
};
/***********************************************
 *
 *  Retreives the logged in user
 *
 ***********************************************/
const AM_getLoggedInUser = () => {
    return AM_getUserFromSession();
};

/***********************************************
 *
 *  Checks if user is authenticated
 *
 ***********************************************/
const AM_isUserAuthenticated = () => {
    const user = AM_getLoggedInUser();
    if (!user || (user && !user.token) || user.verification_required) {
        return false;
    }
    const decoded = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        // console.warn('access token expired');
        return false;
    } else {
        return true;
    }
};
/***********************************************
 *
 *  Sets the logged in user to session storage
 *
 ***********************************************/
const AM_setLoggedInUser = (session) => {
    if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    else {
        sessionStorage.removeItem(AUTH_SESSION_KEY);
    }
};

/***********************************************
 *
 *  Updates the User in the Session
 *
 ***********************************************/
const AM_updateUserInSession = (modifiedUser) => {
    let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
        const user = JSON.parse(userInfo);
        AM_setLoggedInUser({...user, ...modifiedUser});
    }
};
/***********************************************
 *
 *  Sets authorization header for web service calls
 *
 ***********************************************/
const AM_setAuthorization = (token) => {
    if (token) axios.defaults.headers.common['Authorization'] = token;
    else {
        axios.defaults.headers.common['Authorization'] = null;
    }
};

/***********************************************
 *
 *  Check if token is available and set authorization
 *
 ***********************************************/
let currentUser = AM_getUserFromSession();
if (currentUser) {
    const {token} = currentUser;
    if (token) {
        AM_setAuthorization(token);
    }
}

const AM_checkMailingAddress = (address) => {
    // Create parameters to pass to PHP proxy
    let param = USPS_SERVER;
    let xml = '';
    xml += '<AddressValidateRequest USERID="' + USPS_USERID + '">';
    xml += '<Address ID="0">';
    xml += '<FirmName />';
    xml += '<Address1>' + AM_encodeXmlSpecialChars(address.address2) + '</Address1>'; // USPS uses line 2 for street
    xml += '<Address2>' + AM_encodeXmlSpecialChars(address.address1) + '</Address2>'; // USPS uses line 1 for suite/apartment #
    xml += '<City>' + AM_encodeXmlSpecialChars(address.city) + '</City>';
    xml += '<State>' + address.state + '</State>';
    xml += '<Zip5>' + address.zip + '</Zip5>';
    xml += '<Zip4 />';
    xml += '</Address></AddressValidateRequest>';

    param += encodeURIComponent(xml);

    return AM_webServiceGetExternal(param);
}

const AM_toggleImpersonating = (impersonate: Boolean) => {
    if (impersonate) {
        sessionStorage.setItem(IMPERSONATE_KEY, "true");
    } else {
        sessionStorage.removeItem(IMPERSONATE_KEY)
    }
}

const AM_logAction = (searchId: number, action?: number, data?: number) => {
    const url = "/public/planner/logaction";
    const finalData = {
        search_id: searchId,
        action: action,
        data: data
    };
    return AM_webServicePUT(url, finalData);
}

export {
    AM_webServiceGet,
    AM_webServicePOST,
    AM_setTokenToStorage,
    AM_getTokenFromStorage,
    AM_getUserFromSession,
    AM_getLoggedInUser,
    AM_isUserAuthenticated,
    AM_setLoggedInUser,
    AM_updateUserInSession,
    AM_setAuthorization,
    AM_webServiceFileUpload,
    AM_webServiceGetExternal,
    AM_getTokenIfNoAuthRequired,
    AM_webServicePUT,
    AM_webServiceDELETE,
    AM_webServicePOST_JSON,
    AM_checkMailingAddress,
    AM_webServiceMultiTypePost,
    AM_toggleImpersonating,
    AM_logAction
}