const FIREBASE_CONFIG = {
    baystatecommute: {
        apiKey: "AIzaSyDbwXW6SedlPduMj-pHuNKlc0A1S8-9LYI",
        authDomain: "bay-state-commute.firebaseapp.com",
        projectId: "bay-state-commute",
        storageBucket: "bay-state-commute.appspot.com",
        messagingSenderId: "652418758104",
        appId: "1:652418758104:web:0d994f8f5c7b897778eead",
        measurementId: "G-KN9L916JEB"
    },
    alamocommutes: {
        apiKey: "AIzaSyD9AKW3m3wXMe6Fq6E-UVFrvxHH3tNcRnY",
        authDomain: "alamo-commutes.firebaseapp.com",
        databaseURL: "https://alamo-commutes.firebaseio.com",
        projectId: "alamo-commutes",
        storageBucket: "alamo-commutes.appspot.com",
        messagingSenderId: "663854715342",
        appId: "1:663854715342:web:c14a4fca492b35f80b9b9f",
        measurementId: "G-2YQHWH7NKW"
    },
    arrive: {
        apiKey: "AIzaSyC-IlQvdQ8no6A-YBOdXFLfWR8g9g65Afs",
        authDomain: "arrive-rochester.firebaseapp.com",
        databaseURL: "https://arrive-rochester.firebaseio.com",
        projectId: "arrive-rochester",
        storageBucket: "arrive-rochester.appspot.com",
        messagingSenderId: "266477523144",
        appId: "1:266477523144:web:912850ba1b856f164255fd",
        measurementId: "G-QDSVDWEJ05"
    },
    clubridelv: {
        apiKey: "AIzaSyC2wq-DU177PiUfNBMoEIid7aCSQlYCV1w",
        authDomain: "club-ride.firebaseapp.com",
        projectId: "club-ride",
        storageBucket: "club-ride.appspot.com",
        messagingSenderId: "305336254341",
        appId: "1:305336254341:web:d9ffbdc68fb1d22e038eec",
        measurementId: "G-TQ1QZL8HP3"
    },
    commuteconnector: {
        apiKey: "AIzaSyAg1c7eUCdaWJ_7sVQKazIVnTaeQ1MI-D0",
        authDomain: "commute-connector.firebaseapp.com",
        databaseURL: "https://commute-connector.firebaseio.com",
        projectId: "commute-connector",
        storageBucket: "commute-connector.appspot.com",
        messagingSenderId: "743209549933",
        appId: "1:743209549933:web:ef591d2f88965ff3d9bf64",
        measurementId: "G-SZFDL0L54P"
    },
    commutetb: {
        apiKey: "AIzaSyDmg84OdEUuUpSEtclZaVrpqpTxpq9Q_1M",
        authDomain: "commute-tb.firebaseapp.com",
        databaseURL: "https://commute-tb.firebaseio.com",
        projectId: "commute-tb",
        storageBucket: "commute-tb.appspot.com",
        messagingSenderId: "400494930739",
        appId: "1:400494930739:web:ec93166f34be4ca250ac46",
        measurementId: "G-V749TBP759"
    },
    commutesmart: {
        apiKey: "AIzaSyB4leafAnNqBfLWujImaySZ_f8T-udxYhI",
        authDomain: "commutesmart-49782.firebaseapp.com",
        projectId: "commutesmart-49782",
        storageBucket: "commutesmart-49782.appspot.com",
        messagingSenderId: "1068211296320",
        appId: "1:1068211296320:web:135f7e968ed8916949e7a5",
        measurementId: "G-YQJPXG3D4X"
    },
    commutesmartnh: {
        apiKey: "AIzaSyCVbItul2tmDqEnvJRTQV2gQT06cqxfAnM",
        authDomain: "csnh-ade2d.firebaseapp.com",
        projectId: "csnh-ade2d",
        storageBucket: "csnh-ade2d.appspot.com",
        messagingSenderId: "313820833070",
        appId: "1:313820833070:web:010c37d1a056758a8386bf",
        measurementId: "G-ZGCH1TKR0E"
    },
    commuteinfo: {
        apiKey: "AIzaSyB2v1U4b-jV2fC9DlYH2Y6IvX-1s0X74R4",
        authDomain: "commuteinfo-1ade7.firebaseapp.com",
        projectId: "commuteinfo-1ade7",
        storageBucket: "commuteinfo-1ade7.appspot.com",
        messagingSenderId: "613458376169",
        appId: "1:613458376169:web:9a7d8529fb046473714778",
        measurementId: "G-2M5SH7S14F"
    },
    govermont: {
        apiKey: "AIzaSyAmRbJTIk5BRcoNzibWOVFhRYq9SC3XR5E",
        authDomain: "go-vermont.firebaseapp.com",
        databaseURL: "https://go-vermont.firebaseio.com",
        projectId: "go-vermont",
        storageBucket: "go-vermont.appspot.com",
        messagingSenderId: "11418871365",
        appId: "1:11418871365:web:16b1c690e2e3a77d94652c",
        measurementId: "G-84K84J4XRZ"
    },
    rethink: {
        apiKey: "AIzaSyAiFCRtG7m1-TfJCcbWG6WbaCe5FkEA3ek",
        authDomain: "rethink-your-commute.firebaseapp.com",
        databaseURL: "https://rethink-your-commute.firebaseio.com",
        projectId: "rethink-your-commute",
        storageBucket: "rethink-your-commute.appspot.com",
        messagingSenderId: "138408393571",
        appId: "1:138408393571:web:510e8dfd111a737659f156",
        measurementId: "G-Y0GGTKCQN7"
    },
    sharearide: {
        apiKey: "AIzaSyDHnUDqT7qnMJQgU-bNSRW9YcjVbjt4Z5M",
        authDomain: "share-a-ride-46c69.firebaseapp.com",
        projectId: "share-a-ride-46c69",
        storageBucket: "share-a-ride-46c69.appspot.com",
        messagingSenderId: "70601541376",
        appId: "1:70601541376:web:3371483c4a16e56c0f15e5",
        measurementId: "G-DRH1S5YDVB"
    },
    mysmarttrips: {
        apiKey: "AIzaSyBw8cSd8bIbv8nxESVWxo2GaSXCPR17sWU",
        authDomain: "smart-trips-75ebb.firebaseapp.com",
        projectId: "smart-trips-75ebb",
        storageBucket: "smart-trips-75ebb.appspot.com",
        messagingSenderId: "633196512617",
        appId: "1:633196512617:web:c6c1827359a4cc5a2369f8",
        measurementId: "G-L3D8PFKYGK"
    },
    tryparkingit: {
        apiKey: "AIzaSyBiB3yT2v-ehrxrjoaxS9-xdJ4iDVpSqRQ",
        authDomain: "try-parking-it.firebaseapp.com",
        databaseURL: "https://try-parking-it.firebaseio.com",
        projectId: "try-parking-it",
        storageBucket: "try-parking-it.appspot.com",
        messagingSenderId: "395430299627",
        appId: "1:395430299627:web:09784f51949fea38c8fbae",
        measurementId: "G-RHR6KV3QPN"
    },
    crri: {
        apiKey: "AIzaSyAUuBFpBQ5NnWBgH55L4-fpN0RNsihQKT0",
        authDomain: "commute-resource-ri.firebaseapp.com",
        projectId: "commute-resource-ri",
        storageBucket: "commute-resource-ri.appspot.com",
        messagingSenderId: "895585633616",
        appId: "1:895585633616:web:c0b04e824f1bda8651d855",
        measurementId: "G-28JQLC0M8B"
    },
    drivelessri: {
        apiKey: "AIzaSyAUuBFpBQ5NnWBgH55L4-fpN0RNsihQKT0",
        authDomain: "commute-resource-ri.firebaseapp.com",
        projectId: "commute-resource-ri",
        storageBucket: "commute-resource-ri.appspot.com",
        messagingSenderId: "895585633616",
        appId: "1:895585633616:web:c0b04e824f1bda8651d855",
        measurementId: "G-28JQLC0M8B"
    },
    commutepa: {
        apiKey: "AIzaSyDNjhQhtRLBohg62MXJbw7oE2oc9WHLKOw",
        authDomain: "commute-pa.firebaseapp.com",
        projectId: "commute-pa",
        storageBucket: "commute-pa.appspot.com",
        messagingSenderId: "829481915210",
        appId: "1:829481915210:web:504601e1b16dbc8d2327b3",
        measurementId: "G-GD3ZEWBGJX"
    },
    ctrides: {
        apiKey: "AIzaSyC9qeuFRzqMms-CdMUXcr4Oia0hx3nwfy4",
        authDomain: "ctrides-bbeb2.firebaseapp.com",
        projectId: "ctrides-bbeb2",
        storageBucket: "ctrides-bbeb2.appspot.com",
        messagingSenderId: "612663434675",
        appId: "1:612663434675:web:3da85a0df46c6399ac3fc6",
        measurementId: "G-86DMJJQ58N"
    },
    decs: {
        apiKey: "AIzaSyAAXlUmp6kNEqeUQ1FBQBtbYbpdOupiN2A",
        authDomain: "delaware-commute-solutions.firebaseapp.com",
        projectId: "delaware-commute-solutions",
        storageBucket: "delaware-commute-solutions.appspot.com",
        messagingSenderId: "101816060621",
        appId: "1:101816060621:web:3a4c5c6fa75a92f1477784",
        measurementId: "G-TP0S2CFLMN"
    },
    gomaine: {
        apiKey: "AIzaSyD5qkytQ19j0gldTBO8LgH-1FZlS9ZE2Vc",
        authDomain: "go-maine.firebaseapp.com",
        projectId: "go-maine",
        storageBucket: "go-maine.appspot.com",
        messagingSenderId: "717719480618",
        appId: "1:717719480618:web:5399629c9aa668c7c81653",
        measurementId: "G-S6X853NP7S"
    },
    catma: {
        apiKey: "AIzaSyAnqj2ywYjVaGXg-dyG2P68G6ZCkcWTzPg",
        authDomain: "catma-9451a.firebaseapp.com",
        projectId: "catma-9451a",
        storageBucket: "catma-9451a.appspot.com",
        messagingSenderId: "280459937607",
        appId: "1:280459937607:web:4b910d706443cd0fb46e62",
        measurementId: "G-JY5NCDW8L6"
    },
    commuterconnectmi: {
        apiKey: "AIzaSyD8BdOcJVSx0Y5UD0f-FgTSyv6ho3ifoR8",
        authDomain: "commuter-connect.firebaseapp.com",
        projectId: "commuter-connect",
        storageBucket: "commuter-connect.appspot.com",
        messagingSenderId: "814437924745",
        appId: "1:814437924745:web:fd7a71ba4e43283fb72b58",
        measurementId: "G-KY8988ZZNG"
    },
    connectingva: {
        apiKey: "AIzaSyC15wr0b9MBbDa4dBgzDNlN2NXTiOrrqUk",
        authDomain: "connectingva-53426.firebaseapp.com",
        projectId: "connectingva-53426",
        storageBucket: "connectingva-53426.appspot.com",
        messagingSenderId: "149780601201",
        appId: "1:149780601201:web:16da50c650942fa8923ed0",
        measurementId: "G-D2X4WWHGV2"
    },
    staniscruise: {
        apiKey: "AIzaSyCtSZANvM0ufBwp4U7mMgOHhpt5HTUTx6I",
        authDomain: "staniscruise.firebaseapp.com",
        projectId: "staniscruise",
        storageBucket: "staniscruise.appspot.com",
        messagingSenderId: "44118299517",
        appId: "1:44118299517:web:f5ec7a063ddb214cb93214",
        measurementId: "G-X8TSG34Q11"
    },
    waytogokc: {
        apiKey: "AIzaSyAIWtkOjDZM6PpOV3GyBScTbm-xDrH2YNo",
        authDomain: "way-to-go-14e82.firebaseapp.com",
        projectId: "way-to-go-14e82",
        storageBucket: "way-to-go-14e82.appspot.com",
        messagingSenderId: "891622865931",
        appId: "1:891622865931:web:8d71738d052af18397db0e",
        measurementId: "G-B68JP9RGQ5"
    },
    riderollstroll: {
        apiKey: "AIzaSyDZCpRixxP2gJ5HUNg2Pii8UrvJE8kgMx0",
        authDomain: "ride-roll-stroll.firebaseapp.com",
        projectId: "ride-roll-stroll",
        storageBucket: "ride-roll-stroll.appspot.com",
        messagingSenderId: "344819689209",
        appId: "1:344819689209:web:4f893096430e536f622428",
        measurementId: "G-2TZWHPFBXK"
    },
    wmrides: {
        apiKey: "AIzaSyD6ATDMuo0actANrB90YGBVXGI5nFURTPY",
        authDomain: "west-michigan-rides.firebaseapp.com",
        projectId: "west-michigan-rides",
        storageBucket: "west-michigan-rides.appspot.com",
        messagingSenderId: "286447929017",
        appId: "1:286447929017:web:042e503724ccf6b1703d68",
        measurementId: "G-4B0KSFH5TR"
    }
}


export default FIREBASE_CONFIG;